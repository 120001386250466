import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const Aside = makeShortcode("Aside");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page is meant to demonstrate some of the components in action. You can
check out the markdown used for this page
`}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/example/src/pages/demo/index.mdx"
        }}>{`on github`}</a>{`.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Building bonds</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Global accessibility standards</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Small anchor links</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Videos</AnchorLink>
    </AnchorLinks>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Getting started" href="/getting-started" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Theme configuration" href="/guides/configuration" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Contributions" href="/contributions" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz3XTaUzTBxjH8ace8zZT4xJjpkwTFRVpnYTVVv/YgtNsYVOJ4JEIxkTEIx5TNAEPEAUaYPNKjM4zVEExKho80C2KCZRWLrUgUDvxABUVb2j7/5pqYnyzF0/ye/V59X1ks0Mlwwkxh18gSgWacAcSVsJCwwMsk96THqaSraikKO0EmBsQUxmdlOtolOt0jrAjegcR2c1Y6mCTXUW+gEdeIqHFiDYfGVfAXG0VyWNqSda52KK843uzE9EXIkF5SNApJPgUostHgs//D3ioFQk+R695NYzOecnvaV7iV6gk6N18F1qOGEqQkCIGrHSjO/yWoJ1t9FtcjwSeISKz6SvQrpJRC9G5r+i1yE3UP5DeCFlLYEU86P56TfcF9fSNbWRIWiuxJ9+TnNjCnw0QVw59lzURvvMZlrt+0IekVvqHl+AdLRgKOth/DwovPyR+Qj7W3DcsroaArS4Gb7hDdClYr35gfWgJB/ZVc9wN04t8jMxpYd2NdtKqQNKdYEqyIYFWfj7djssDaxduZVAXA+56SHdBn6hLaIx5JJRBWwcYtTOZPX4Vz97B3H9BJhYSFHuRbbdB0mpgxLzzyKhjaA+8IasU9sS0smpkKbt3NbPABt2mFSEheURegb3F7STpbRQWP2f/f2Aq8CEhZ+kflsuWChC/Omp+EfLDIQbEuYi2qCRGwXJjHbMW3SUgtRXRnUTGHmXgmgfEnOtg3d+vSaqBWVdVvk1oQgJP0N9kJaUSZHsNzDnoYmDERZbq29g4w0fcH160iU/ovaQRMVUiEwqQ8VZEcdAztp7hlqcEbHpMt2jnp3a/US7wS0YV226BpN6ClDIPG2beJ8vgI8voIUfvZehkJ2IsRWOuRH48gWiPIuabyKRyZKINMZSjmeJA9DbMlkfkNII/Qdl8zcPSaXZWj6gmc4pKhuIhM8zLMFMdEm6n668N9PitgR6RdXSNvIeE+7/p5qfrNLUC+cnG1OxmMms/d/gRW4lAVhe+peQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
    </Row>
    <PageDescription mdxType="PageDescription">
      <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    </PageDescription>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <h2>{`Section heading`}</h2>
        <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Add-on repositories, or repos, are components built for a specific product or
experience. The repos are built on top of the core Carbon repo, and enable`}</p>
        </blockquote>
        <p>{`IBMers believe in progress—that application of intelligence, reason and science
can improve business, society and the human condition.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside mdxType="Aside">
          <p>
  <strong>
    Good design is always
    <br />
    good design.
  </strong>
          </p>
          <p>{`What we borrow from our own design history is not a mid-century aesthetic in
stylistic terms, but the modernist attitudes and approach used at the time.`}</p>
        </Aside>
      </Column>
    </Row>
    <h2>{`Small anchor links`}</h2>
    <p>{`Swab barque interloper™ chantey doubloon starboard grog black jack gangway
rutters π.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Header</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Header again</AnchorLink>
    </AnchorLinks>
    <h3>{`Header`}</h3>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    <h3>{`Header again`}</h3>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm.
Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen
halter furl. Swab barque interloper chantey doubloon starboard grog black jack
gangway rutters.`}</p>
    <ol>
      <li parentName="ol">{`This is a demonstration of an ordered list item. This list item is
particularly long to demonstrate how the text wraps and aligns with the first
line.`}
        <ol parentName="li">
          <li parentName="ol">{`This is a nested list item, it should wrap in the same way. Typically,
nested list items should be of the same type (ordered, unordered) as their
parent.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Ordered list item`}</li>
      <li parentName="ol">{`Ordered list item`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`This is a demonstration of an unordered list item. This list item is
particularly long to demonstrate how the text wraps and aligns with the first
line. With `}<inlineCode parentName="li">{`a code snippet`}</inlineCode>{` and `}<inlineCode parentName="li">{`yet another code snippet`}</inlineCode>{` and also then
`}<a parentName="li" {...{
          "href": "http://carbondesignsystem.com/"
        }}>{`a link as well`}</a>{`. This is also a
`}<a parentName="li" {...{
          "href": "http://carbondesignsystem.com/"
        }}>{`a second link`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`This is a nested list item, it should wrap in the same way. Typically,
nested list items should be of the same type (ordered, unordered) as their
parent.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Unordered list item`}</li>
      <li parentName="ul">{`Unordered list item`}</li>
    </ul>
    <h2>{`Images`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Large blocky illustration",
        "title": "Large blocky illustration",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/large-image.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/large-image.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/large-image.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/large-image.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h2>{`Videos`}</h2>
    <Video title="Eyes" vimeoId="310583077" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      